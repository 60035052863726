<template>
  <NavBar />
  <SectionHead />
  <SectionServices />
  <SectionAbout />
  <SectionClients />
  <SectionCTA />
  <SectionContact />
  <SectionFooter />
</template>

<script>
import NavBar from './components/NavBar.vue';
import SectionHead from './components/SectionHead.vue';
import SectionServices from './components/SectionServices.vue';
import SectionAbout from './components/SectionAbout.vue';
//import SectionClients from './components/SectionClients.vue';
import SectionCTA from './components/SectionCTA.vue';
import SectionContact from './components/SectionContact.vue';
import SectionFooter from './components/SectionFooter.vue';


export default {
  name: 'App',
  components: {
    NavBar,
    SectionHead,
    SectionServices,
    SectionAbout,
//    SectionClients,
    SectionCTA,
    SectionContact,
    SectionFooter
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
